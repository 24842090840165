import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid } from '@mui/material';

import logo from './toroto.png';
import logo_closed from './toroto_closed.png';
import dexscreener from './dexscreener.png';
import x from './x.png';
import telegram from './telegram.png';
import pump from './pump.png';
import game from './game.png';


import './App.css';



// Styled component for the image
const BlinkingImage = styled.img`
  height: 400px;
  @media (max-width: 768px) {
    height: 300px;

  }
`;


const StyledIcon = styled.img`
  margin-top: 20px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition for smooth animation */

  /* Define hover effect */
  &:hover {
    transform: scale(1.5); /* Increase size by 50% */
  }

`

const BlinkingComponent = () => {
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBlink(prevBlink => !prevBlink);
    }, 1000); // Change 1000 to adjust blink speed

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {/* Render the appropriate image based on state */}
      {blink ? <BlinkingImage src={logo} alt="Image 1" /> : <BlinkingImage src={logo_closed} alt="Image 2" />}
    </div>
  );
};



function App() {
  return (
    <div className="App">
      <Grid container direction="row" spacing={5} justifyContent={"center"} alignItems={"center"}>

        <Grid item>
          <StyledIcon src={dexscreener} onClick={() => window.open("https://dexscreener.com/solana/n4arcd2t3kkeajubymvxoq8fbshpbec3fyh6nwnxxoz", "_blank")}></StyledIcon>
        </Grid>
        <Grid item>
          <StyledIcon src={x} onClick={() => window.open("https://x.com/TorotoCoin", "_blank")}></StyledIcon>
        </Grid>
        <Grid item>
          <StyledIcon src={telegram} onClick={() => window.open("https://t.me/Torotoonsol", "_blank")}></StyledIcon>
        </Grid>
      </Grid>



      <header className="App-header">

        <BlinkingComponent />
        <p>
        </p>

      </header>
    </div>
  );
}

export default App;
